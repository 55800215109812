define("discourse/plugins/paczki-close-topic/discourse/connectors/topic-footer-main-buttons-before-create/close-topic", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n"], function (_exports, _component, _object, _computed, _service, _ajax, _ajaxError, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CloseTopic extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "topic", [(0, _computed.alias)("args.outletArgs.topic")]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "isEnabled", [(0, _computed.readOnly)("siteSettings.close_topic_enabled")]))();
    #isEnabled = (() => (dt7948.i(this, "isEnabled"), void 0))();
    get isCategoryAllowed() {
      const allowedCategories = this.allowedCategories || [];
      const currentCategoryId = this.currentCategoryId;
      return allowedCategories.includes(currentCategoryId);
    }
    get isUserAllowed() {
      const currentUserId = this.currentUser?.id;
      return currentUserId === this.topic.user_id;
    }
    get allowedCategories() {
      return (this.siteSettings.close_topic_categories || "").split("|").map(id => parseInt(id, 10)).filter(id => id);
    }
    static #_7 = (() => dt7948.n(this.prototype, "allowedCategories", [(0, _object.computed)("siteSettings.close_topic_categories")]))();
    get currentCategoryId() {
      this.categoryId = this.composer.topic.category_id;
      return this.categoryId;
    }
    get shouldShowField() {
      if (this.topic.closed) {
        return false;
      }
      if (this.currentUser.admin) {
        return true;
      }
      return this.isEnabled && this.isCategoryAllowed && this.isUserAllowed;
    }
    openDialog(event) {
      event?.preventDefault();
      this.dialog.alert({
        message: _discourseI18n.default.t("close_topic.message"),
        buttons: [{
          icon: "lock",
          label: _discourseI18n.default.t("close_topic.just_close"),
          class: "btn",
          action: () => this.closeTopic(false)
        }, {
          icon: "dollar-sign",
          label: _discourseI18n.default.t("close_topic.close_mark_sold"),
          class: "btn-primary",
          action: () => this.closeTopic(true)
        }, {
          label: _discourseI18n.default.t("composer.cancel")
        }]
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "openDialog", [_object.action]))();
    async closeTopic(mark_sold) {
      this.saving = true;
      try {
        await (0, _ajax.ajax)(`/topic/close/${this.topic.id}`, {
          type: "PUT",
          data: {
            mark_sold
          }
        });
        this.dialog.alert(_discourseI18n.default.t("close_topic.action_success"));
        location.reload();
      } catch (err) {
        (0, _ajaxError.popupAjaxError)(err);
      } finally {
        this.saving = false;
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "closeTopic", [_object.action]))();
  }
  _exports.default = CloseTopic;
});