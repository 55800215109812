define("discourse/plugins/paczki-close-topic/discourse/templates/connectors/topic-footer-main-buttons-before-create/close-topic", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldShowField}}
    <DButton
      @id="btn-close-topic"
      @icon="lock"
      @label="close_topic.title"
      @title="close_topic.title"
      @disabled={{this.saving}}
      {{on "click" (fn this.openDialog)}}
    />
  {{/if}}
  */
  {
    "id": "FFNV4XEQ",
    "block": "[[[41,[30,0,[\"shouldShowField\"]],[[[1,\"  \"],[8,[39,1],[[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"openDialog\"]]],null]],null]],[[\"@id\",\"@icon\",\"@label\",\"@title\",\"@disabled\"],[\"btn-close-topic\",\"lock\",\"close_topic.title\",\"close_topic.title\",[30,0,[\"saving\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"on\",\"fn\"]]",
    "moduleName": "discourse/plugins/paczki-close-topic/discourse/templates/connectors/topic-footer-main-buttons-before-create/close-topic.hbs",
    "isStrictMode": false
  });
});